<template>
  <span
    :class="[
      'product-label',
      `product-label-${type}`,
      `product-label-size-${size}`,
    ]"
  >
    {{ text }}
  </span>
</template>

<script>
  export default {
    props: {
      text: {
        type: String,
        default: null,
      },
      size: {
        type: String,
        default: "default",
        validator: (value) => {
          return ["default", "large"].includes(value)
        },
      },

      type: {
        type: String,
        default: "new",
        validator: (value) => {
          return [
            "new",
            "discontinued",
            "clearance",
            "sale",
            "bestseller",
            "shipping",
            "staff-pick",
            "trending",
            "one-oz-sale",
          ].includes(value)
        },
      },
    },
  }
</script>

<style lang="scss" scoped>

  .product-label {
    font-size: $fontsize-xs;
    font-weight: bold;
    line-height: $base-spacing * 6;
    display: block;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 100px;
    padding-inline: $base-spacing * 2;
    &:first-letter {
      text-transform: capitalize;
    }
    &-size-large {
      line-height: $base-spacing * 8;
      padding-inline: $base-spacing * 3;
      font-size: $fontsize-sm;
    }
    &-new {
      color: white;
      background: $green-text;
    }
    &-discontinued {
      color: white;
      background: $onyx;
    }
    &-clearance {
      color: white;
      background: $green-text;
    }
    &-sale {
      color: white;
      background: $green-text;
    }
    &-bestseller {
      color: white;
      background: $blue-text;
    }
    &-shipping {
      font-weight: normal;
      color: $yellow-text;
      box-shadow: 0 0 0 1px $yellow-text;
      background: $yellow-tint;
    }
    &-trending {
      color: white;
      background: $purple-text;
    }
    &-one-oz-sale {
      color: white;
      background: $purple-text;
    }
    &-staff-pick {
      color: $navy-tint;
      background: $navy-text;
    }
  }
</style>
